interface Article {
  readonly publishedAt: string | null
  readonly slug: { readonly current: string | null } | null
}

const articlePath = ({ publishedAt, slug }: Article): string => {
  if (!publishedAt) {
    return `/${slug?.current}`
  }
  const path = publishedAt.split('-').join('/')
  return `/${path}/${slug?.current}`
}

export default articlePath
