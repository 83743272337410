import React from 'react'
import styled from 'styled-components'
import { graphql, HeadProps, Link } from 'gatsby'
import { Layout, BlockRenderer, HeadTags, Footer } from '@components'
import { articlePath } from '@utilities'

interface PageProps {
  data: Queries.IndexPageQuery
}

const IndexPage = ({ data: { sanityPage } }: PageProps) => {
  if (!sanityPage) return <></>
  return (
    <Layout images={sanityPage.stills?.map(still => still?.asset?.url || '') || []}>
      <ContentWrapper>
        <Content>
          {sanityPage?.copy?.map(
            (copySet, i) =>
              !!copySet && (
                <CopySet key={`copyset-${i}`}>
                  <Copy>
                    <BlockRenderer>{copySet._rawCopy}</BlockRenderer>
                  </Copy>
                </CopySet>
              ),
          )}
          {!!sanityPage.selectedArticles && sanityPage.selectedArticles.length > 0 && (
            <CopySet>
              <Copy>
                <h3>Selected Works</h3>
                <ul>
                  {sanityPage.selectedArticles.map(
                    (article, i) =>
                      !!article && (
                        <li key={`article-${i}`}>
                          <Link to={articlePath(article)}>{article.title}</Link>
                        </li>
                      ),
                  )}
                </ul>
              </Copy>
            </CopySet>
          )}
        </Content>
      </ContentWrapper>
      <Footer />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  z-index: 10;
  padding: 20px 20px 50px 20px;
  min-height: calc(100vh - 112px);
  min-height: 100dvh;
  @media only screen and (min-width: 744px) {
    min-height: calc(100vh - 104px);
    padding: 40px 40px 80px 40px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(15, 5, 0, 0.85);
  backdrop-filter: blur(50px);
`

const Content = styled.article`
  position: relative;
  z-index: 10;
  padding-bottom: 50px;
  @media only screen and (min-width: 744px) {
    width: 640px;
    margin: auto;
  }

  p {
    font-size: 15px;
    @media only screen and (min-width: 744px) {
      font-size: 20px;
    }
  }

  h2 {
    font-size: 30px;
    @media only screen and (min-width: 744px) {
      font-size: 40px;
    }
    margin: 1em 0 0.5em 0;
  }

  h3 {
    font-size: 20px;
    @media only screen and (min-width: 744px) {
      font-size: 24px;
    }
    margin: 1em 0 0.5em 0;
  }

  li {
    list-style-type: '➣ ';
  }
`

const CopySet = styled.div`
  break-inside: avoid;
`

const Copy = styled.section``

export const query = graphql`
  query IndexPage {
    sanityPage(slug: { current: { eq: "home" } }) {
      title
      copy {
        title
        _rawCopy
      }
      stills {
        asset {
          url
        }
      }
      selectedArticles {
        title
        publishedAt
        slug {
          current
        }
      }
    }
  }
`

export const Head = (props: HeadProps) => <HeadTags {...props} />

export default IndexPage
